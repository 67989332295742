li {
    border-radius: (5rem / 100);
    overflow: hidden;
}

// 志愿者详情
.volunteer-detail-bottom-right {
    width: 100%;

    &-title {
        padding-left: (24rem / 100);
        height: (80rem / 100);
        line-height: (80rem / 100);
        font-size: (28rem / 100);
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        color: #292929;
        border-bottom: (1rem / 100) solid #E2E2E2;
    }

    .volunteer-detail-bottom-right-list {
        width: 100%;

        &-ul {
            width: 100%;
            padding: (20rem / 100) (24rem / 100) 0;

            &-li {
                width: 100%;
                height: (236rem / 100);
                margin-bottom: (30rem / 100);
                background: #FFFFFF;
                border: (1rem / 100) solid #E8E8E8;
                cursor: pointer;

                &-cover {
                    width: 100%;
                    height: (189rem / 100);
                    margin-bottom: (14rem / 100);
                }


                .volunteer-detail-bottom-right-list-ul-li-bottom-title {
                    float: left;
                    margin-left: (12rem / 100);
                    line-height: (18rem / 100);
                    font-size: (18rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    color: #0A0A0A;
                }

                .volunteer-detail-bottom-right-list-ul-li-bottom-people {
                    float: right;
                    margin-right: (12rem / 100);
                    line-height: (18rem / 100);
                    font-size: (18rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    color: #0A0A0A;
                }

            }

        }
    }
}

// 社团详情
.organization-detail-bottom-right {
    width: 100%;

    &-title {
        padding-left: (24rem / 100);
        height: (80rem / 100);
        line-height: (80rem / 100);
        font-size: (28rem / 100);
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        color: #292929;
        border-bottom: (1rem / 100) solid #E2E2E2;
    }

    .organization-detail-bottom-right-list {
        width: 100%;

        &-ul {
            width: 100%;
            padding: (20rem / 100) (24rem / 100) 0;

            &-li {
                width: 100%;
                height: (315rem / 100);
                margin-bottom: (30rem / 100);
                background: #FFFFFF;
                border: (1rem / 100) solid #E8E8E8;
                cursor: pointer;

                &-cover {
                    width: 100%;
                    height: (190rem / 100);
                    margin-bottom: (14rem / 100);
                }


                .organization-detail-bottom-right-list-ul-li-bottom-title {
                    margin-left: (12rem / 100);
                    margin-bottom: (14rem / 100);
                    line-height: (18rem / 100);
                    font-size: (18rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    color: #0A0A0A;
                }

                .organization-detail-bottom-right-list-ul-li-bottom-people {
                    margin-left: (12rem / 100);
                    width: (258rem / 100);
                    line-height: (26rem / 100);
                    font-size: (15rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    color: #303030;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }

            }

        }
    }
}

// 活动详情
.activity-detail-bottom-right {
    width: 100%;

    &-title {
        padding-left: (24rem / 100);
        height: (80rem / 100);
        line-height: (80rem / 100);
        font-size: (28rem / 100);
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        color: #292929;
        border-bottom: (1rem / 100) solid #E2E2E2;
    }

    .activity-detail-bottom-right-list {
        width: 100%;

        &-ul {
            width: 100%;
            padding: (20rem / 100) (24rem / 100) 0;

            &-li {
                width: 100%;
                height: (300rem / 100);
                margin-bottom: (30rem / 100);
                background: #FFFFFF;
                border: (1rem / 100) solid #E8E8E8;
                cursor: pointer;

                &-cover {
                    width: 100%;
                    height: (190rem / 100);
                    margin-bottom: (14rem / 100);
                }


                .activity-detail-bottom-right-list-ul-li-bottom-title {
                    margin-left: (12rem / 100);
                    margin-bottom: (14rem / 100);
                    line-height: (18rem / 100);
                    font-size: (18rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    color: #0A0A0A;
                }

                .activity-detail-bottom-right-list-ul-li-bottom-people {
                    margin-left: (12rem / 100);

                    &-address,
                    &-date {
                        height: (16rem / 100);
                        line-height: (16rem / 100);
                        font-size: (14rem / 100);
                        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                        color: rgba($color: #0A0A0A, $alpha: 0.6);
                        margin-bottom: (10rem / 100);

                        &-icon {
                            float: left;
                            margin-right: (5rem / 100);
                            width: (16rem / 100);
                            height: (16rem / 100);
                        }

                        div {
                            float: left;
                            width: (280rem / 100);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    &-date {
                        margin-bottom: 0;
                    }


                }

            }

        }
    }
}

// 培训
.culture-detail-bottom-right {
    width: 100%;

    &-title {
        padding-left: (24rem / 100);
        height: (80rem / 100);
        line-height: (80rem / 100);
        font-size: (28rem / 100);
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        color: #292929;
        border-bottom: (1rem / 100) solid #E2E2E2;
    }

    .culture-detail-bottom-right-list {
        width: 100%;

        &-ul {
            width: 100%;
            padding: (20rem / 100) (24rem / 100) 0;

            &-li {
                width: 100%;
                height: (270rem / 100);
                margin-bottom: (30rem / 100);
                background: #FFFFFF;
                border: (1rem / 100) solid #E8E8E8;
                cursor: pointer;

                &-cover {
                    width: 100%;
                    height: (189rem / 100);
                    margin-bottom: (10rem / 100);
                }


                .culture-detail-bottom-right-list-ul-li-bottom-title {
                    margin-left: (12rem / 100);
                    margin-bottom: (10rem / 100);
                    width: (297rem / 100);
                    line-height: (26rem / 100);
                    height: (26rem / 100);
                    font-size: (18rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    color: #0A0A0A;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    
                }

                .culture-detail-bottom-right-list-ul-li-bottom-people {
                    margin-left: (12rem / 100);
                    width: (258rem / 100);
                    line-height: (14rem / 100);
                    font-size: (14rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;

                    color: rgba($color: #333B47, $alpha: 0.6);
                }

            }

        }
    }
}

// 点单
.order-detail-bottom-right {
    width: 100%;

    &-title {
        padding-left: (24rem / 100);
        height: (80rem / 100);
        line-height: (80rem / 100);
        font-size: (28rem / 100);
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        color: #292929;
        border-bottom: (1rem / 100) solid #E2E2E2;
    }

    .order-detail-bottom-right-list {
        width: 100%;

        &-ul {
            width: 100%;
            padding: (20rem / 100) (24rem / 100) 0;

            &-li {
                width: 100%;
                height: (330rem / 100);
                margin-bottom: (30rem / 100);
                background: #FFFFFF;
                border: (1rem / 100) solid #E8E8E8;
                cursor: pointer;

                &-cover {
                    width: 100%;
                    height: (190rem / 100);
                    margin-bottom: (14rem / 100);
                }


                .order-detail-bottom-right-list-ul-li-bottom-title {
                    margin-left: (12rem / 100);
                    margin-bottom: (10rem / 100);
                    width: (289rem / 100);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: (17rem / 100);
                    font-size: (17rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    color: #0A0A0A;

                }

                .order-detail-bottom-right-list-ul-li-bottom-type-box {
                    margin-left: (12rem / 100);
                    height: (26rem / 100);
                    margin-bottom: (15rem / 100);

                    div {
                        float: left;
                    }

                    &-type {
                        padding: 0 (12rem / 100);
                        height: 100%;
                        line-height: (26rem / 100);
                        border-radius: (50rem / 100);
                        border: (1rem / 100) solid #8492C6;
                        font-size: (14rem / 100);
                        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                        color: #8492C6;
                        margin-right: (10rem / 100);
                    }

                    &-status {
                        padding: 0 (12rem / 100);
                        height: 100%;
                        line-height: (26rem / 100);
                        border-radius: (50rem / 100);
                        background: #8492C6;
                        color: #FFFFFF;
                    }
                }

                .order-detail-bottom-right-list-ul-li-bottom-host,
                .order-detail-bottom-right-list-ul-li-bottom-summary {
                    margin-left: (12rem / 100);
                    margin-bottom: (10rem / 100);
                    width: (289rem / 100);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: (14rem / 100);
                    font-size: (14rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    color: rgba($color: #0A0A0A, $alpha: 0.6);
                }

            }

        }
    }
}

// 直播
.live-detail-bottom-right {
    width: 100%;

    &-title {
        padding-left: (24rem / 100);
        height: (80rem / 100);
        line-height: (80rem / 100);
        font-size: (28rem / 100);
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        color: #292929;
        border-bottom: (1rem / 100) solid #E2E2E2;
    }

    .live-detail-bottom-right-list {
        width: 100%;

        &-ul {
            width: 100%;
            padding: (20rem / 100) (24rem / 100) 0;

            &-li {
                width: 100%;
                height: (330rem / 100);
                margin-bottom: (30rem / 100);
                background: #FFFFFF;
                border: (1rem / 100) solid #E8E8E8;
                cursor: pointer;

                &-cover {
                    width: 100%;
                    height: (190rem / 100);
                    margin-bottom: (14rem / 100);
                }


                .live-detail-bottom-right-list-ul-li-bottom-title {
                    margin-left: (12rem / 100);
                    margin-bottom: (10rem / 100);
                    width: (289rem / 100);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: (17rem / 100);
                    font-size: (17rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    color: #0A0A0A;

                }

                .live-detail-bottom-right-list-ul-li-bottom-type-box {
                    margin-left: (12rem / 100);
                    height: (26rem / 100);
                    margin-bottom: (15rem / 100);

                    div {
                        float: left;
                    }

                    &-type {
                        padding: 0 (12rem / 100);
                        height: 100%;
                        line-height: (26rem / 100);
                        border-radius: (50rem / 100);
                        border: (1rem / 100) solid #8492C6;
                        font-size: (14rem / 100);
                        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                        color: #8492C6;
                        margin-right: (10rem / 100);
                    }

                    &-status {
                        padding: 0 (12rem / 100);
                        height: 100%;
                        line-height: (26rem / 100);
                        border-radius: (50rem / 100);
                        background: #8492C6;
                        color: #FFFFFF;

                        div {
                            float: left;
                            margin-right: (5rem / 100);
                            margin-left: (12rem / 100);
                        }

                        &-icon {
                            width: (12rem / 100);
                            height: (12rem / 100);
                            float: left;
                            margin-top: (7rem / 100);
                        }
                    }
                }

                .live-detail-bottom-right-list-ul-li-bottom-host,
                .live-detail-bottom-right-list-ul-li-bottom-summary {
                    margin-left: (12rem / 100);
                    margin-bottom: (10rem / 100);
                    width: (289rem / 100);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: (14rem / 100);
                    font-size: (14rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    color: rgba($color: #0A0A0A, $alpha: 0.6);
                }

            }

        }
    }
}

// 资源（视频）
.resources-detail-bottom-right {
    width: 100%;

    &-title {
        padding-left: (24rem / 100);
        height: (80rem / 100);
        line-height: (80rem / 100);
        font-size: (28rem / 100);
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        color: #292929;
        border-bottom: (1rem / 100) solid #E2E2E2;
    }

    .resources-detail-bottom-right-list {
        width: 100%;

        &-ul {
            width: 100%;
            padding: (20rem / 100) (24rem / 100) 0;

            &-li {
                width: 100%;
                height: (315rem / 100);
                margin-bottom: (30rem / 100);
                background: #FFFFFF;
                border: (1rem / 100) solid #E8E8E8;
                cursor: pointer;

                &-cover {
                    width: 100%;
                    height: (190rem / 100);
                    margin-bottom: (14rem / 100);
                }


                .resources-detail-bottom-right-list-ul-li-bottom-title {
                    margin-left: (12rem / 100);
                    margin-bottom: (10rem / 100);
                    width: (289rem / 100);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: (17rem / 100);
                    font-size: (17rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    color: #0A0A0A;

                }

                .resources-detail-bottom-right-list-ul-li-bottom-summary {
                    margin-left: (12rem / 100);
                    margin-bottom: (10rem / 100);
                    width: (289rem / 100);
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    line-height: (26rem / 100);
                    font-size: (15rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    color: rgba($color: #0A0A0A, $alpha: 0.6);
                }

            }

        }
    }
}

// 非遗
.intangible-detail-bottom-right {
    width: 100%;

    &-title {
        padding-left: (24rem / 100);
        height: (80rem / 100);
        line-height: (80rem / 100);
        font-size: (28rem / 100);
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        color: #292929;
        border-bottom: (1rem / 100) solid #E2E2E2;
    }

    .intangible-detail-bottom-right-list {
        width: 100%;

        &-ul {
            width: 100%;
            padding: (20rem / 100) (24rem / 100) 0;

            &-li {
                width: 100%;
                height: (315rem / 100);
                margin-bottom: (30rem / 100);
                background: #FFFFFF;
                border: (1rem / 100) solid #E8E8E8;
                cursor: pointer;

                &-cover {
                    width: 100%;
                    height: (190rem / 100);
                    margin-bottom: (14rem / 100);
                }


                .intangible-detail-bottom-right-list-ul-li-bottom-title {
                    margin-left: (12rem / 100);
                    margin-bottom: (10rem / 100);
                    width: (289rem / 100);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: (17rem / 100);
                    font-size: (17rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    color: #0A0A0A;

                }

                .intangible-detail-bottom-right-list-ul-li-bottom-summary {
                    margin-left: (12rem / 100);
                    margin-bottom: (10rem / 100);
                    width: (289rem / 100);
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    line-height: (26rem / 100);
                    font-size: (15rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    color: rgba($color: #0A0A0A, $alpha: 0.6);
                }

            }

        }
    }
}

// 书籍
.resources_t-detail-bottom-right {
    width: 100%;

    &-title {
        padding: (30rem / 100) (24rem / 100) (17rem / 100);
        font-size: (28rem / 100);
        line-height: (28rem / 100);
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        color: #292929;
    }

    .resources_t-detail-bottom-right-list {
        padding: 0 (24rem / 100);

        &-ul {
            width: 100%;
            padding-bottom: (40rem / 100);

            &-li {
                height: (60rem / 100);
                width: 100%;
                line-height: (60rem / 100);
                border-bottom: (1rem / 100) solid #E2E2E2;
                font-size: (20rem / 100);
                font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                color: #0A0A0A;
                cursor: pointer;
            }
        }
    }
}

// 非遗
.news-detail-bottom-right {
    width: 100%;

    &-title {
        padding-left: (24rem / 100);
        height: (80rem / 100);
        line-height: (80rem / 100);
        font-size: (28rem / 100);
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        color: #292929;
        border-bottom: (1rem / 100) solid #E2E2E2;
    }

    .news-detail-bottom-right-list {
        width: 100%;

        &-ul {
            width: 100%;
            padding: (20rem / 100) (24rem / 100) 0;

            &-li {
                width: 100%;
                height: (315rem / 100);
                margin-bottom: (30rem / 100);
                background: #FFFFFF;
                border: (1rem / 100) solid #E8E8E8;
                cursor: pointer;


                &-cover {
                    width: 100%;
                    height: (190rem / 100);
                    margin-bottom: (14rem / 100);
                }


                .news-detail-bottom-right-list-ul-li-bottom-title {
                    margin-left: (12rem / 100);
                    margin-bottom: (10rem / 100);
                    width: (289rem / 100);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: (17rem / 100);
                    font-size: (17rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    color: #0A0A0A;

                }

                .news-detail-bottom-right-list-ul-li-bottom-summary {
                    margin-left: (12rem / 100);
                    margin-bottom: (10rem / 100);
                    width: (289rem / 100);
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    line-height: (26rem / 100);
                    font-size: (15rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    color: rgba($color: #0A0A0A, $alpha: 0.6);
                }

            }

        }
    }
}

// 地图
.map-detail-bottom-right {
    width: 100%;

    &-title {
        padding-left: (24rem / 100);
        height: (80rem / 100);
        line-height: (80rem / 100);
        font-size: (28rem / 100);
        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
        color: #292929;
        border-bottom: (1rem / 100) solid #E2E2E2;
    }

    .map-detail-bottom-right-list {
        width: 100%;

        &-ul {
            width: 100%;
            padding: (20rem / 100) (24rem / 100) 0;

            &-li {
                width: 100%;
                height: (300rem / 100);
                margin-bottom: (30rem / 100);
                background: #FFFFFF;
                border: (1rem / 100) solid #E8E8E8;
                cursor: pointer;

                &-cover {
                    width: 100%;
                    height: (190rem / 100);
                    margin-bottom: (14rem / 100);
                }


                .map-detail-bottom-right-list-ul-li-bottom-title {
                    margin-left: (12rem / 100);
                    margin-bottom: (14rem / 100);
                    line-height: (18rem / 100);
                    font-size: (18rem / 100);
                    font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                    color: #0A0A0A;
                }

                .map-detail-bottom-right-list-ul-li-bottom-people {
                    width: 100%;
                    margin-left: (12rem / 100);

                    &-address,
                    &-date {
                        height: (16rem / 100);
                        line-height: (16rem / 100);
                        font-size: (14rem / 100);
                        font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                        color: rgba($color: #0A0A0A, $alpha: 0.6);
                        margin-bottom: (10rem / 100);

                        &-icon {
                            float: left;
                            margin-right: (5rem / 100);
                            width: (16rem / 100);
                            height: (16rem / 100);
                        }

                        div {
                            width: (280rem / 100);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            float: left;
                        }
                    }

                    &-date {
                        margin-bottom: 0;
                    }
                }

            }

        }
    }
}